export default {
  home: '/',
  activity: '/aktivnost/:id',

  // Payment page
  payment: `/placilo/:uid`,

  // Errors
  error: '/napaka',
  error400: '/napaka/400',
  error401: '/napaka/401',
  error403: '/napaka/403',
  error404: '/napaka/404',
  error500: '/napaka/500',
};