import LocalizedStrings from 'react-localization';

const languages = ['sl'];

const strings = new LocalizedStrings({
  sl: {
    appName: 'Prijavni sistem 404',
    takeMeHome: 'Domov',
    cookies: 'Piškotki',
    iAgree: 'strinjam se',
    and: 'in',

    // activity
    couldNotLoadActivityData: 'Napaka pri nalaganju podatkov za prijavo.',
    loadingData: 'Nalaganje podatkov.',
    signUpDisabled: 'Prijave onemogočene',
    signUpDisabledDescription:
      'Prijave za to aktivnost trenutno niso omogočene.',
    signUpNotYetActive: 'Prijave še niso mogoče',
    signUpNotYetActiveDescription: 'Prijave se odprejo',
    signUpNotActiveAnymore: 'Prijave zaključene',
    signUpNotActiveAnymoreDescription:
      'Prijave na to aktivnost so žal že zaključene.',
    signUpFor: 'Prijave na',
    paymentMethod: 'Način plačila',
    price: 'cena',
    youHaveToChooseYourPaymentMethod: 'Izbrati morate način plačila.',
    cardPayment: 'Plačilo s kartico',
    free: 'brezplačno',
    trr: 'Bančno nakazilo',
    submitApplication: 'oddaj prijavo',
    numberOfInstallments: 'Število obrokov',
    iAgreeWith: 'Strinjam se s',
    fieldIsRequired: (fieldName) => `Polje '${fieldName}' je obvezno.`,
    fieldEmailRequired: 'Vnesti morate pravilen email naslov',
    iAgreeWithPrivacyPolicy: 'politiko zasebnosti',
    iAgreeWithTerms: 'pogoji poslovanja',
    youHaveToAgreeWithPrivacyPolicy:
      'Strinjati se morate s politiko zasebnosti in pogoji poslovanja.',
    youHaveToPassRecaptcha: 'Pokazati morate, da niste robot.',
    submitError: 'Napaka pri oddajanju prijave.',
    submitErrorDescription: 'Prosimo poskusite kasneje.',
    submitErrorText: 'Poskusi znova',
    submitSuccess: 'Prijava oddana',
    submitSuccessDescription:
      'Poslali smo vam potrditveno e-pošto na vpisani naslov.',
    submitSuccessText: 'Oddaj novo prijavo',
    signUpRemoved: 'Prijava odstranjena',
    signUpRemovedDescription:
      'Iskana prijavnica je bila označena kot odjavljena.',
    submitSuccessAdditionalButtonsTitle: 'Preveri še druge aktivnosti:',

    // date picker
    cancel: 'Prekliči',
    clear: 'Pobriši',
    ok: 'Ok',
    today: 'Danes',

    // errors
    errorUnknown: 'Neznana napaka.',
    404: 'Iskana stran za prijave ne obstaja.',

    // payments
    loadingPaymentsError: 'Napaka pri nalaganju plačil',
    loadingPaymentsErrorDescription: 'Prosimo poskusite pozneje',
    paymentFor: 'Plačilo za',
    forPerson: 'za osebo',
    paid: 'Plačano',
    pay: 'Plačaj',
    payInAdvance: 'Plačaj vnaprej',
    companyName: '404: Mladinski tehnološko - razvojni center',
    companyAddress: 'Mencingerjeva ulica 7',
    companyCity: '1000 Ljubljana',
  },
});

strings.setLanguage('si');

export { languages };
export default strings;
