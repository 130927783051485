import ErrorPage from './ErrorPage';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../util/routes';

export default props => {
  return (
    <Switch>
      {/* we could read error code from url, but this forces us to think about all the errors and write error messages */}
      <Route path={routes.error400} exact render={props => <ErrorPage errorCode={400} {...props} />}/>
      <Route path={routes.error401} exact render={props => <ErrorPage errorCode={401} {...props} />}/>
      <Route path={routes.error403} exact render={props => <ErrorPage errorCode={403} {...props} />}/>
      <Route path={routes.error404} exact render={props => <ErrorPage errorCode={404} {...props} />}/>
      <Route path={routes.error500} exact render={props => <ErrorPage errorCode={500} {...props} />}/>

      {/*route not found, redirect to error 404 page*/}
      <Redirect to={routes.error404} />
    </Switch>
  );
};