import React, { Component, Suspense, lazy } from 'react';
import routes from './util/routes';
import { defaultStyles } from './util/styles';
import ErrorDialog from './errors/ErrorDialog';
import Loading from './components/Loading';
import { CssBaseline, withStyles } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorContext, parseApiError } from './util/errorHandler';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import Errors from './errors';

const Home = lazy(() => import('./Home'));
const PaymentPage = lazy(() => import('./PaymentPage'))

// set moment locale
moment.locale('sl');

const styles = theme => ({
  ...defaultStyles(theme),
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDialogOpen: false,
      errorCode: '',
    };
  }

  showErrorDialog = errorCode => {
    this.setState({
      errorDialogOpen: true,
      errorCode: errorCode,
    });
  };

  closeDialog = () => {
    this.setState({
      errorDialogOpen: false,
      errorCode: '',
    });
  };

  handleApiError = (reason, history) => {
    // Function passed in react context, it is retrieved from context using
    // withErrorHandler higher order component. It should be called on axios
    // error. On critical error (i.e. no permission) user is redirected to
    // error page. If error is not critical just a dialog is shown.
    const redirectToErrorPage = errorCode => {
      history.push(routes.error);
      this.setState({
        errorCode: errorCode,
      });
    };

    const redirect = route => {
      history.push(route);
    };

    parseApiError(reason, redirectToErrorPage, this.showErrorDialog, redirect);
  };

  appRouter = () => {
    const {classes} = this.props;
    return (
      <div style={{flexGrow: 1}}>
        <BrowserRouter className={classes.content}>
          <Suspense fallback={<Loading/>}>
            <Switch>
              {/* errors */}
              <Route path={routes.error} component={Errors}/>

              {/* home - form page */}
              <Route path={routes.activity} component={Home}/>

              {/* payment page */}
              <Route path={routes.payment} component={PaymentPage} />

              {/* route not found, redirect to error 404 page */}
              <Redirect to={routes.error404}/>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  };

  render() {
    const {classes} = this.props;
    return (
        <div className={classes.root}>
          <CssBaseline/>
          <ErrorContext.Provider value={this.handleApiError}>
            {this.appRouter()}
          </ErrorContext.Provider>
          <ErrorDialog
            open={this.state.errorDialogOpen}
            errorCode={this.state.errorCode}
            onClose={this.closeDialog}
          />
        </div>
    );
  }
}

export default withStyles(styles)(App);