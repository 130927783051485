import { makeStyles } from '@material-ui/core';
import { Orange, DisabledColor, Blue } from './theme';
import grey from '@material-ui/core/colors/grey';

const defaultStyles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  },
  textWhite: {
    color: 'white'
  },
  textOrange: {
    color: Orange
  },
  textCenter: {
    textAlign: 'center'
  },
  iconYellow: {
    color: Orange
  },
  w100: {
    width: '100%'
  },
  h100: {
    height: '100%'
  },
  robotoMono: {
    fontFamily: "'Roboto Mono', monospace"
  },
  leftButtonIcon: {
    marginRight: theme.spacing(1)
  },
  floatLeft: {
    float: 'left'
  },
  floatRight: {
    float: 'right'
  },
  textGrey: {
    color: grey[800]
  },
  fontWeightLight: {
    fontWeight: 200
  },
  overflowYScroll: {
    overflowY: 'scroll'
  },
  overflowXScroll: {
    overflowX: 'auto'
  },
  homeCardContent: {
    overflowY: 'auto',
    maxHeight: 512
  },
  customDisabledInput: {
    '& .MuiInputBase-input.Mui-disabled ': {
      color: DisabledColor
    },
    '& .Mui-disabled': {
      color: DisabledColor
    }
  },
  textOverfloEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  divOutline: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: grey[400]
  },
  flexGrow: {
    flexGrow: 1
  },
  hideScrollBar: {
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent'
    }
  },
  link: {
    textDecoration: 'none',
    color: Blue
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  subtitle: {
    // fontWeight: 400,
    marginBottom: -6,
  },
  title: {
    color: theme.palette.secondary.main,
  },
  price: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
  priceTitle: {
    textTransform: 'uppercase',
    // fontWeight: 400,
  },
  priceName: {
    fontFamily: 'Roboto Mono',
    textTransform: 'uppercase',
    fontSize: 11,
    color: theme.palette.secondary.main,
    fontWeight: 400,
  },
  horizontalScroll: {
    overflowX: 'auto',
    overflowY: 'hidden'
  },
});

// hook for use in functions
const useStyles = makeStyles(defaultStyles);

export { useStyles, defaultStyles };