import { createMuiTheme } from '@material-ui/core/styles';

const Orange = 'rgba(243, 165, 29, 1)';
const Blue = 'rgba(0, 73, 123, 1)';
const MediumBlue = 'rgba(0, 73, 123, 0.7)';
const LightBlue = 'rgba(0, 73, 123, 1)';
const White = '#FFFFFF';
const MediumWhite = 'rgba(255, 255, 255, 0.5)';
const DisabledColor = 'rgba(22, 28, 31, 1)';
const Dark = 'rgba(60, 60, 60, 1)';
const LightDark = 'rgba(60, 60, 60, 0.1)';
const MediumDark = 'rgba(60, 60, 60, 0.2)';
const BoldDark = 'rgba(60, 60, 60, 0.4)';
const HeavyDark = 'rgba(60, 60, 60, 0.8)';
const HeaviestDark = 'rgba(60, 60, 60, 0.95)';

const robotoMono = [
  '-apple-system',
  'Roboto Mono',
  'monospace',
].join(',');

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: Orange,
    },
    secondary: {
      main: Blue,
    },
  },
  typography: {
    h1: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    h2: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    h3: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    h4: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    h5: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    h6: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    button: {
      fontWeight: 300,
      fontFamily: robotoMono,
    },
    overline: {
      fontWeight: 300,
    },
    subtitle1: {
      fontFamily: robotoMono,
    },
    subtitle2: {
      fontFamily: robotoMono,
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 300,
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 300,
    },
    caption: {
      fontFamily: 'Roboto',
      fontWeight: 300,
    },
  },
});

export {
  Orange,
  Blue,
  MediumBlue,
  White,
  MediumWhite,
  LightBlue,
  Dark,
  LightDark,
  HeavyDark,
  MediumDark,
  BoldDark,
  HeaviestDark,
  DisabledColor,
  Theme,
};