import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button
} from '@material-ui/core';
import strings from '../strings';

export default ({ open, errorCode, onClose, ...props }) => {
  // convert error code to human readable error message
  let message = strings[errorCode];

  if (!message) {
    message = strings.errorUnknown;
  }

  // dialog shown when handleApiError is called
  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogTitle>{strings.error}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={onClose}>
          {strings.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};