import React from 'react';
import routes from './routes';

const ErrorContext = React.createContext(() => {});

// Higher order component:
// adds handleApiError function to props of the component.
function withErrorHandler(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ErrorContext.Consumer>
          {callbackFunction => (
            <WrappedComponent
              handleApiError={callbackFunction}
              {...this.props}
            />
          )}
        </ErrorContext.Consumer>
      );
    }
  };
}

function parseApiError(reason, redirectToErrorPage, openDialog, redirect) {
  try {
    if (reason.response) {
      const status = reason.response.status;
      // get error code from server, all error codes must have localized versions in strings.js
      const errorCode = reason.response.data.response_error;

      if (status === 403 || status === 401) {
        // forbidden, user cannot access this page
        redirectToErrorPage(errorCode);
      } else if (status === 404) {
        redirect(routes.error404);
      } else {
        openDialog(errorCode);
      }
    }
  } catch (error) {
    redirectToErrorPage('');
  }
}

export { ErrorContext, withErrorHandler, parseApiError };