import React, { Component } from 'react';
import strings from '../strings';
import { defaultStyles } from '../util/styles';
import { withRouter } from 'react-router-dom';
import { Container, withStyles, Grid, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = theme => ({
  ...defaultStyles(theme),
  uppercase: {
    textTransform: 'uppercase',
  },
  title: {
    color: theme.palette.secondary.main,
  },
});

class ErrorPage extends Component {
  render() {
    const {errorCode, classes} = this.props;
    // get error message from error code (message is saved in localized strings)
    let errorMessage = strings[errorCode];
    if (errorMessage == null || errorMessage === '') {
      errorMessage = strings['errorUnknown'];
    }

    return <Container maxWidth='sm'>
      <Box pt={20}>
        <Grid container direction='row' justify='center' alignItems='center' className={classes.container}
              spacing={2}>
          <Grid item>
            <Typography variant='h1' className={clsx(classes.textCenter, classes.title)}>
              {errorCode}
            </Typography>
          </Grid>
          <Grid item xs={12}/>
          <Grid item>
            <Typography variant='h6' className={classes.textCenter}>
              {errorMessage}
            </Typography>
          </Grid>
          <Grid item xs={12}/>
          <Grid item xs={12}/>
        </Grid>
      </Box>
    </Container>;
  }
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number.isRequired,
};

export default withRouter(withStyles(styles)(ErrorPage));