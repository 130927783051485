import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Theme } from './util/theme';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


ReactDOM.render(
  <Fragment>
    {/* Date and time picker localization */}
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={Theme}>
        <App />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
